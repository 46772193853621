<template>
  <v-container id="payments-worldline">
    <advanced-table :title="title" :headers="headers" :endpoint="endpoint" :transform="transform" :loading="loading" :refresh="refresh"
                    :feature-period="false" :feature-exporter="false" :feature-printer="false" @success="success" @fail="fail"
    />
  </v-container>
</template>

<script>
  import i18n from '@/i18n';
  import { displayISO } from '@/util/DateUtil';
  import AdvancedTable from '@/views/components/common/AdvancedTable';

  export default {
    name: 'PaymentsWorldline',
    components: { AdvancedTable },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        title: this.$t('customerService.payments.title'),
        headers: [
          {
            text: this.$i18n.t('customerService.payments.column.id'),
            align: 'start',
            filterable: false,
            sortable: false,
            value: 'id'
          },
          {
            text: this.$i18n.t('customerService.payments.column.customerName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerName'
          },
          {
            text: this.$i18n.t('customerService.payments.column.amount'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'amount'
          },
          {
            text: this.$i18n.t('customerService.payments.column.method'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'method'
          },
          {
            text: this.$i18n.t('customerService.payments.column.status'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'status'
          },
          {
            text: this.$i18n.t('customerService.payments.column.error'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'error'
          },
          {
            text: this.$i18n.t('customerService.payments.column.date'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'date'
          }
        ],
        endpoint: 'payment/transactions/worldline',
        transform: {
          id: (id) => {
            if (typeof id !== 'string') {
              return id;
            }
            return id.substring(0, 10);
          },
          amount: (amount) => String(amount) + i18n.t('common.price.currency.EUR.symbol'),
          method: (method) => i18n.t('customerService.payments.method.' + method),
          status: (status) => i18n.t('customerService.payments.status.' + status),
          date: displayISO
        }
      };
    },
    methods: {
      success () {
        this.$emit('success');
      },
      fail (error, message) {
        this.$emit('fail', error, message);
      }
    }
  };
</script>
